import React from 'react'
import {NavLink} from "react-router-dom";
import styles from './nav.module.sass'
const Nav = (props) => {

    
    return (    
                <nav className={styles.nav}>
                    <div className={styles.nav_bg}>
                    <ul className={styles.nav_menu}>
                        <li className={styles.nav_menu_item}>
                            <NavLink to="/" className={styles.nav_menu_link}>
                                Главная
                            </NavLink>
                        </li>
                        <li className={styles.nav_menu_item}>
                            <NavLink to="/catalog" className={styles.nav_menu_link}>
                                Каталог
                            </NavLink>
                        </li>
                        <li className={styles.nav_menu_item}>
                            <NavLink to="/about" className={styles.nav_menu_link}>
                                О нас
                            </NavLink>
                        </li>
                        <li className={styles.nav_menu_item}>
                            <NavLink to="/contacts" className={styles.nav_menu_link}>
                                Контакты
                            </NavLink>
                        </li>
                        <li className={styles.nav_menu_item}>
                            <NavLink to='/' className={styles.nav_menu_link} onClick={()=>props.scrollToTop(true)}>
                                Новинки
                            </NavLink>
                        </li>
                    </ul>
                    </div>
                </nav>
    )
}
export default Nav