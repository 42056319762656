import React, { useEffect,useRef } from 'react'
import './home.sass'
import fl1 from '../../img/delete/slider1/slider_1.jpg'
import fl2 from '../../img/delete/slider1/slider_2.jpg'
import fl3 from '../../img/delete/slider1/slider_3.jpg'
import fl4 from '../../img/delete/slider1/slider_4.jpg'
import bestoffer1 from '../../img/bestoffer/1.png'
import bestoffer2 from '../../img/bestoffer/2.png'
import bestoffer3 from '../../img/bestoffer/3.png'
import bestoffer4 from '../../img/bestoffer/4.png'
import bestoffer5 from '../../img/bestoffer/5.png'
import bestoffer6 from '../../img/bestoffer/6.png'
import { NavLink } from 'react-router-dom'
import SliderItem from '../../components/slider/slider'
import useFetch from '../../components/hooks/useFetch'
import Preloader from '../../components/preloader/preloader'
const Home = (props) => {
    const newSlider = useRef(null)
    const [{ response: partnersResponse, isLoading: loadingPartners }, doFetchPartners] = useFetch(`partner/`)
    const [{ response: manufactResponse, isLoading: loadingManufact }, doFetchManufact] = useFetch('manufactor/')
    useEffect(() => {
            doFetchPartners()
            doFetchManufact()
    }, [])

    useEffect(()=>{
        window.scrollTo({top:0})
       },[])

    useEffect(()=>{
        
        if(props.newSlider) {
            window.scrollTo({ top: newSlider.current.offsetTop, behavior: "smooth" })
            setTimeout(()=>{props.scrollToTop(false)},5000)
        }
        
   },[props.newSlider])
    return (
        <>
            <div className="slider">
                <div className="slider_content">
                    <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel" data-interval="20000">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={fl1} className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-block">
                                    <h5>ООО "Сако ГРИН"</h5>
                                    <p>Продажа товаров оптом</p>
                                    <NavLink to='/catalog' className="carousel-caption-btn">Перейти в каталог</NavLink>

                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={fl2} className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-block">
                                    <h5>Семена от ведущих  производителей <br/> России, Италии и Польши</h5>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={fl3} className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-block">
                                    <h5>Все для успешного роста и развития на Вашем участке</h5>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={fl4} className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-block">
                                    <h5>Ваш урожай - наша забота!</h5>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            
            <div ref={newSlider}>
                <SliderItem  id={"new"} bg={"bestseller bestseller_new"} title={"Новинки"} apiUrl={'product/'} options={{ new: true }} />
            </div>
            
            <section className="bestoffer">
                <div className="container">
                    <div className="title title__bestoffer">
                        <h2>ЛУЧШИЕ ПРЕДЛОЖЕНИЯ ДЛЯ ВАШЕГО САДА, ОГОРОДА И ДОМА</h2>
                    </div>
                    <ul className="bestoffer_content">
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer1} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Исключены поставки некачественной продукции  
                                </p>
                                <p className="bestoffer_content__descr">
                                Качество нашей продукции подтверждено сертификатами. Тщательно упаковываем и проверяем каждую партию товара.
                                </p>
                            </div>
                        </li>
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer4} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Всегда есть из чего выбрать 
                                </p>
                                <p className="bestoffer_content__descr">
                                Ассортимент насчитывает более 2000 позиций и каждый сезон обновляется. Прямые поставки от производителей. 
                                </p>
                            </div>
                        </li>
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer2} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Доставляем продукцию в любую точку РБ     
                                </p>
                                <p className="bestoffer_content__descr">
                                Заказы выполняются в кратчайшие сроки. Самовывоз возможен при любой сумме заказа со склада в Минске.     
                                </p>
                            </div>
                        </li>
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer5} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Индивидуальный подход к клиенту
                                </p>
                                <p className="bestoffer_content__descr">
                                Гибкая система скидок и индивидуальные условия сотрудничества. Вам не нужно переплачивать.  
                                </p>
                            </div>
                        </li>
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer3} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Опыт работы более 8 лет
                                </p>
                                <p className="bestoffer_content__descr">
                                Мы зарекомендовали себя в качестве надежного поставщика продукции отличного качества.   
                                </p>
                            </div>
                        </li>
                        <li className="bestoffer_content__element">
                                <img className="bestoffer_content__img" src={bestoffer6} alt="1"/>
                            <div className="bestoffer_content__text">
                                <p className="bestoffer_content__title">
                                Гарантии
                                </p>
                                <p className="bestoffer_content__descr">
                                Работаем с возвратами нереализованной продукции.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <SliderItem id={'best'} bg={"bestseller bestseller_hit"} title={"Хит продаж"} apiUrl={'product/'} options={{ bestseller: true }} />
            <section className="wholesale">
                <div className="container">
                    <div className="wholesale_content">
                        <div className="title">
                            <h2>Мы работаем с любыми клиентами, на различных условиях.</h2>
                        </div>
                        <div className="wholesale_container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="descr_element">
                                        <div className="descr_container">
                                            <div className="descr_text">
                                                <p className="indent">
                                                    <strong>
                                                    ООО «Сако ГРИН» занимается оптовой продажей:
                                                    </strong>
                                                    <span className='bigsize'>
                                                        <strong>
                                                        {' семян овощных и цветочных культур; удобрений; средств защиты растений от вредителей, болезней и сорняков; микроудобрений и препаратов от грызунов; садовым инвентарем и бытовой химии.'}
                                                        </strong>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="descr_element">
                                        <div className="descr_container">
                                            <div className="descr_text">
                                            <p className="indent">Для того, чтобы приобрести товар оптом, Вам необходимо скачать прайс-лист и заполнить форму заказа.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="descr_element">
                                        <div className="descr_container">
                                            <div className="descr_text">
                                                <p className="indent">
                                                Уточнить условия сотрудничества, действующие скидки и другую интересующую информацию, Вы можете любым из указанных ниже способов:<br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="descr_element">
                                        <div className="descr_container">
                                            <div className="descr_text">
                                                <p style={{marginBottom: '15px'}}>
                                                    <strong>{'По электронной почте:  '}  
                                                    <a className="mail" href="mailto:trade@sako.by">trade@sako.by</a>
                                                    </strong>
                                                </p>
                                                <p style={{marginBottom: '10px'}}>
                                                    <strong>По контактным телефонам:</strong>
                                                </p>
                                                <p className="indent">
                                                    <a className="phone" href="tel:+375173569803">
                                                        <strong>Тел./факс +375(17) 356-98-03</strong>
                                                    </a>
                                                </p>
                                                <p className="indent">
                                                    <a className="phone" href="tel:+375296884044">
                                                        <strong>Т.моб. +375(29) 688-40-44</strong>
                                                    </a>
                                                </p>
                                                <p className="indent">
                                                    <a className="phone" href="tel:+375296884048">
                                                        <strong>Т.моб. +375(29) 688-40-48</strong>
                                                    </a>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="manufacturers">
                <div className="container">
                    <div className="manufacturers_content">
                        <div className="title">
                            <h2>Производители, которых Мы представляем в Беларуси!</h2>
                        </div>
                        <div className="row">
                            {loadingManufact && <Preloader />}
                            {!loadingManufact && manufactResponse && manufactResponse.map(el => {
                                if(el.name === 'ООО "Сако ГРИН"'){
                                    return
                                }
                                return (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 pr-1 pl-1" key={el.id}>
                                        <NavLink to={`/catalog/manufactures/${el.id}`}>
                                            <div className="manufacturers_item">
                                                <div className="manufacturers_item_link" href="#">
                                                    <img src={el.logo} alt="logo_manufacture" />
                                                </div>
                                                <div className="manufacturers_item_company">
                                                    {el.name}
                                                </div>
                                                <div className="manufacturers_item_production">
                                                    Продукция
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}
export default Home