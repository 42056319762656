import React, {useState} from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import logo from '../../img/logo/logo.png';
import logo_zima from '../../img/logo/logo_zima.png';
import styles from './header.module.sass'
const Header = () => {
    const [state, setState] = useState('')
    const navigate = useNavigate();
    const onSearch = (e) =>{
        e.preventDefault()
        navigate("/catalog",{replace:true,state})
        setState('')
    }
    return (
            <header className={styles.header}>
                    <div className={styles.header_content}>
                            <div className={styles.header_search}>
                                <form tabIndex={'0'} className={styles.header_search_container}>
                                    <input onChange={e => setState(e.target.value)} 
                                    type="text" 
                                    className={styles.header_search_input} 
                                    placeholder="Поиск товаров" 
                                    value={state}/>
                                        <button onClick={e =>onSearch(e)} className={styles.header_search_input_btn}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                </form>
                                
                            </div>
                            <div className={styles.header_logo} href="#">
                                <div className={styles.header_logo_img}>
                                    <img src={logo} alt="logo"/>
                                </div>
                                <div className={styles.header_logo_text}><strong>Товары для дома, сада и огорода</strong></div>
                            </div>
                            <div className={styles.header_phone}>
                                <div className={styles.header_phone_content}>
                                    <span>
                                        <a href="tel:+375173569803">Тел./факс +375(17) 356-98-03</a>
                                    </span>
                                    <span>
                                        <a href="tel:+375296884044">моб. +375(29) 688-40-44</a>
                                    </span>
                                    <span>
                                        <a href="tel:+375296884048">моб. +375(29) 688-40-48</a>
                                        
                                    </span>
                                    <span>
                                        Email:
                                         <a href="mailto:trade@sako.by">trade@sako.by</a>
                                    </span>
                                </div>
                            </div>
                    </div>
            </header>
    )
}
export default Header