import React, { useEffect, useState} from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import styles from './productList.module.sass'
const ProductList = ({state,url}) => {
    const [stateProduct, setStateProduct] = useState('')
    const navigate = useNavigate()
    useEffect(()=>{
        
        setStateProduct(state)
    },[state])
    const clickProduct = (id)=> navigate(`/catalog/product/${id}`)
    const onKeyDown = (e,id) =>{
        if(e.keyCode === 13){
            clickProduct(id)
        }
        
    }
    return (
        <div>
            <ul className={styles.products_container}>
            {stateProduct && stateProduct.map((el) =>
            <li tabIndex={'0'} onKeyDown={e=>onKeyDown(e,el.id)} className={styles.navlink}  key={el.id}>
                <div className={styles.products_element} >
                    <img onClick={()=> clickProduct(el.id)} className={styles.products_element_img} src={el.main_logo} alt='product_img'/>
                    <span onClick={()=> clickProduct(el.id)} className={styles.products_element_name}>{el.name}</span>
                    <NavLink tabIndex={'-1'} to={`/catalog/manufactures/${el.manufactor.id}`}><span className={styles.products_element_manufacturers}>
                        {el.manufactor.name !== 'ООО "Сако ГРИН"' ? el.manufactor.name : null}
                        </span></NavLink>
                    
                </div>
            </li>
                
             )}
            
            </ul>
        </div>
    )
}
export default ProductList