import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import styles from './scrollToTop.module.sass'

const ScrollToTop = () => {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);

    useEffect(() => {
        if (pageYOffset > 400) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return false;
    }

    return (
        <i onClick={scrollToTop} className={styles.scrollToTop}>
            <i className="fas fa-chevron-up"></i>
        </i>
        
    );
};

export default ScrollToTop;