import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from "react-router-dom";
import styles from './catalog.module.sass'
import ProductList from './productList/productList'
import useFetch from '../../components/hooks/useFetch'
import Preloader from '../../components/preloader/preloader';

const Catalog = () => {
    const searchParams = useLocation().state
    let url = useParams()
    let slug = url.slug
    
    const [state, setState] = useState('')
    const [menuCategory, setMenuCategory] = useState(false)
    const [availabilityOrBestseller, setAvailabilityOrBestseller] = useState()
    const [next, setNext] = useState('')
    const [fetching, setFetching] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    
    const [{ response, error, isLoading }, doFetch] = useFetch('category/')
    const [{ response: responseAvailability, isLoading: isLoadingAvailability }, doFetchAvailability] = useFetch('product/')


    useEffect(() => {
        doFetch()
        const availability = slug == 'availability' ? true : ''
        const bestseller = slug == 'bestseller' ? true : ''
        const category_ids = slug == 'product' ? url.id : ''
        const manufactor = slug == 'manufactures' ? url.id : ''
        doFetchAvailability({
            params: {
                search: searchParams,
                category_ids: slug !== 'availability' && slug !== 'bestseller' ? slug : category_ids,
                new: "",
                manufactor: manufactor,
                availability: availability,
                bestseller: bestseller,
                page: 1,
                page_size: 15
            }
        })
    }, [])
    useEffect(() => {

        if (slug == 'manufactures' && url.id) {
            doFetchAvailability({
                params: {
                    manufactor: url.id,
                    page: 1,
                    page_size: 15
                }
            })
        } else if (searchParams) {
            doFetchAvailability({
                params: {
                    search: searchParams,
                    page: 1,
                    page_size: 15
                }
            })
        } else if (availabilityOrBestseller) {
            doFetchAvailability({
                params: availabilityOrBestseller
            })
        }
    }, [availabilityOrBestseller, searchParams, slug])
    const menuClick = (menuCategory) => {
        doFetchAvailability({
            params: {
                category_ids: menuCategory,
                page: 1,
                page_size: 15
            }
        })
    }

    useEffect(() => {

        if (responseAvailability) {
            setState(responseAvailability.results)
            setTotalCount(responseAvailability.count)
            setNext(responseAvailability.next ? 'https' + responseAvailability.next.slice(4) : null)

        }
    }, [responseAvailability])

    useEffect(() => {
        if (fetching && responseAvailability) {
            fetch(next)
                .then(res => res.json())
                .then(body => {
                    setState([...state, ...body.results])
                    setTotalCount(body.count)
                    setNext(body.next ? 'https' + body.next.slice(4) : null)
                    
                })
                .finally(() => setFetching(false))
        }
    }, [fetching])
    const loadMore = () => {
        setFetching(true)
    }
    const clickCategory = () =>{
        menuCategory ? setMenuCategory(false) : setMenuCategory(true)
    }
    
    const routeAvaOrBes = (route) =>{
        setMenuCategory(false)
        if (route === "availability"){
            setAvailabilityOrBestseller({
                availability: true,
                page: 1,
                page_size: 15
        })
        }
        if (route === "bestseller"){
            setAvailabilityOrBestseller({
                bestseller: true,
                page: 1,
                page_size: 15
        })
        }
        
    }
    return (
        <div className={styles.bg}>
            <div className='container'>
                <div className={styles.catalog}>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className={styles.menu}>
                                <span className={styles.categories}>КАТЕГОРИИ ТОВАРОВ</span>
                                {isLoading && <Preloader />}
                                {!isLoading && response && <Menu menuClick={menuClick}
                                                                 routeAvaOrBes={routeAvaOrBes}
                                                                 response={response}
                                                                 setMenuCategory={setMenuCategory}
                                                            />}
                            </div>

                        </div>
                        <div className={`col-md-9 pl-0 pr-0 ${styles.burgerMenu}`}>
                            
                                <div className={styles.menu767}>
                                    <span onClick={()=>clickCategory()} className={styles.categories767}>
                                        КАТЕГОРИИ ТОВАРОВ
                                        {!menuCategory && <div className={styles.circ_close}>&lt;</div>}
                                        {menuCategory && <div className={styles.circ_open}>&lt;</div>}
                                    </span>
                                    {isLoading && menuCategory && <Preloader />}
                                    
                                </div>
                                {!isLoading && response && menuCategory && <div  className={styles.menu_category}>
                                        {!isLoading && response && <Menu menuClick={menuClick}
                                                                    routeAvaOrBes={routeAvaOrBes}
                                                                    response={response}
                                                                    setMenuCategory={setMenuCategory}
                                                                />}
                                    </div>}
                            
                            
                            {isLoading || isLoadingAvailability && <Preloader />}
                            {state && state.length < 1 && !isLoading && !isLoadingAvailability && <div style={{ "fontSize": "40px" }}>Товар не найден</div>}
                            {state && !isLoading && !isLoadingAvailability && <ProductList state={state} url={url} />}
                            {fetching && <Preloader />}
                            {!fetching && state && state.length != totalCount && (
                                <div className={styles.loadmore}>
                                    <button onClick={() => loadMore()}>загрузить еще</button>
                                </div>

                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>


    )
}

const Menu = ({response, setMenuCategory, routeAvaOrBes, menuClick}) => {
    const [subCategory, setSubCategory] = useState()

    function SortArray(x, y){
        if (x.name.toUpperCase() < y.name.toUpperCase()) {return -1;}
        if (x.name.toUpperCase() > y.name.toUpperCase()) {return 1;}
        return 0;
    }
    const data = response.sort(SortArray);

    const subCategoryShow = (item) =>{
        
       subCategory !== item ? setSubCategory(item) : setSubCategory(null)
    }
    
    const onKeyDown = (e,i) =>{
        if(e.keyCode === 13){
            subCategoryShow(i+1)
        }
        
    }
    const subMenu = ['Семена фирмы Аэлита (Россия)', 'Семена фирмы FRANCHI (Италия)', 'Семена фирмы PNOS (Польша)']
    const seedMenu = subMenu.map((item, i) =>{
    return <Fragment key={item}>
            <li tabIndex={"0"} onKeyDown={e=>onKeyDown(e,i)} onClick={() =>subCategoryShow(i+1)} className={styles.subcategory} >
                <span className={styles.subcategory_position}>
                    {item}
                
                    <span className={styles.openToClose}>
                        {subCategory == i + 1 ? <span>&rsaquo;</span> : <span>&lsaquo;</span>}
                    </span>
                </span> 
            </li>
            <ul className={`${styles.colapss_body} ${subCategory == i + 1 ? styles.show : ''}` }>
                {data.map(el => {
                    if(el.name.includes(i+1)){
                        return (
                            <li className={styles.menuli} key={el.id}>
                                <NavLink onClick={() =>{
                                    setMenuCategory(false)
                                    menuClick(el.id)}} 
                                    className={styles.navlink} 
                                    to={`/catalog/${el.id}`}>
                                    {el.name.substr(0,el.name.length-2)}
                                </NavLink>
                            </li>

                        )
                    }
                        
                    })}
            </ul>
    </Fragment>})
    return (
        <ul>    
            <li className={styles.menuli} >
                <NavLink onClick={() => routeAvaOrBes("availability") } 
                className={styles.navlink} 
                to={`/catalog/availability`}>Товары в наличии</NavLink>
            </li>
            <li className={styles.menuli} >
                <NavLink onClick={() => routeAvaOrBes("bestseller")} 
                className={styles.navlink} 
                to={`/catalog/bestseller`}>Хит продаж</NavLink>
            </li>
            {seedMenu}
            
            {data.map(el => {
                if(el.name.includes('Семена') || el.name.includes('1')|| el.name.includes('2') || el.name.includes('3')){
                    return
                }
                return (    
                            <li className={styles.menuli} key={el.id}>
                                <NavLink key={el.id} 
                                        onClick={() =>{
                                        setMenuCategory(false)
                                        menuClick(el.id)}} 
                                        className={styles.navlink} 
                                        to={`/catalog/${el.id}`}>
                                    {el.name}
                                </NavLink>
                            </li>

                )
            })}
            </ul>
    )
}
export default Catalog