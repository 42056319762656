import React,{useState} from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Nav from "./components/nav/nav";
import Home from './page/home/home'
import About from "./page/about/about";
import Contacts from "./page/contacts/contacts";
import Catalog from "./page/catalog/catalog";
import Product from "./page/catalog/product/product";
import ScrollToTop from "./components/sctollToTop/scrollToTop";

const App = () => {
   const [newSlider,setNewSlider] = useState(false)
   
   const scrollToTop = (flag) => setNewSlider(flag);
    return (
        <>
            <Router>
                <Header/>
                <Nav scrollToTop={scrollToTop}/>
                <Routes>
                  <Route exact path='/' element={<Home scrollToTop={scrollToTop} newSlider={newSlider}/>}/>
                  <Route path='/about' element={<About/>}/>
                  <Route path='/contacts' element={<Contacts/>}/>
                  <Route path='/catalog' element={<Catalog/>}/>
                  <Route path='/catalog/:slug' element={<Catalog/>}/>
                  <Route path='/catalog/product/:id' element={<Product/>}/>
                  <Route path='/catalog/:slug/:id' element={<Catalog/>}/>
                </Routes>
            <Footer/>
            <ScrollToTop/>
            </Router>
        </>
    );
}

export default App;
